define('element', ['$console'], function($console) {


  /**
   * Public DOM manipulation methods
   */
  class elementsModule {
    constructor() {
      this.elements = [];
      this.element = undefined;
    }

    /**
     * Parse selector into elements array
     * @param selector
     * @param [contains]
     * @param [attribute]
     * @returns {elementsModule}
     */
    init(selector, contains, attribute) {
      var parse = selector => {

        if (this.isArray(selector)) {
          selector.forEach(selector => {
            parse(selector);
          });
        }

        if (typeof selector === 'string') {
          let results = document.querySelectorAll(selector);
          if (results.length) {
            return this.elements.push(results);
          }
        }

        if (this.isDomElement(selector)) {
          this.element = selector;
          return selector;
        }

        if (this.isNodeList(selector)) {
          return this.elements.push(selector);
        }
      };

      this.elements = [];
      this.element = undefined;

      if (!selector) {
        return this;
      }

      parse(selector);

      if (contains) {
        this.containsString(contains, attribute);
      }

      return this;
    }

    /**
     * Check if object is an Array
     * @param object
     * @returns {boolean}
     */
    isArray(object) {
      if (!Array.isArray) {
        return Object.prototype.toString.call(object) === '[object Array]';
      }
      return Array.isArray(object);
    }

    /**
     * Check for NodeList
     * @param object
     * @returns {boolean}
     */
    isNodeList(object) {
      return (typeof object === 'object' && object.length &&
        (object.length === 0 || (typeof object[0] === 'object' && object[0].nodeType === 1))) ||
        (!String(object) ? object.toString() : String(object)).indexOf('NodeList') !== -1;
    }

    /**
     * Check for DOM Element
     * @param element
     * @returns {boolean}
     */
    isDomElement(element) {
      return window.HTMLElement !== undefined ? element instanceof HTMLElement : element.nodeType === 1;
    }

    /**
     * Get preferred element
     * @param [element]
     */
    preferredElement(element) {
      return element || (this.element || this.elements);
    }

    /**
     * Add event to HTML element(s)
     * @param event
     * @param callback
     * @param [element]
     * @param {boolean} [useCapture]
     * @returns {object}
     */
    on(event, callback, element, useCapture = false) {
      const ARGUMENTS_MIN = 2;
      const ARGUMENTS_MAX = 4;
      if (arguments.length < ARGUMENTS_MIN || arguments.length > ARGUMENTS_MAX) {
        $console.error('Invalid amount of arguments', `Expected ${ARGUMENTS_MIN} or ${ARGUMENTS_MAX}, got ${arguments.length}`, 'on');
      }

      element = this.preferredElement(element);

      if (this.isDomElement(element)) {
        element.addEventListener(event, callback, useCapture);
        return this;
      }

      if (this.isArray(element) || this.isNodeList(element)) {
        for (var i = 0; i < element.length; i++) {
          this.on(event, callback, element[i]);
        }
      }
      return this;
    }

    /**
     * Remove event from HTML element(s)
     * @param event
     * @param callback
     * @param [element]
     * @returns {object}
     */
    off(event, callback, element) {
      if (arguments.length !== 2 && arguments.length !== 3) {
        $console.error('Invalid amount of arguments', `Expected 2 or 3, got ${arguments.length}`, 'off');
      }

      element = this.preferredElement(element);

      if (this.isDomElement(element)) {
        element.removeEventListener(event, callback);
        return this;
      }

      if (this.isArray(element) || this.isNodeList(element)) {
        for (var i = 0; i < element.length; i++) {
          this.off(event, callback, element[i]);
        }
      }
      return this;
    }

    /**
     * Removes all events registered with this element
     * @param element
     */
    removeEvents(element) {
      element = this.preferredElement(element);

      if (this.isDomElement(element)) {
        let clone = element.cloneNode(true);
        element.parentNode.replaceChild(clone, element);
        return this;
      }
      return this;
    }

    /**
     * Get attribute
     * @param {string} [attribute]
     * @param {object} [element]
     * @returns {string}
     */
    getAttribute(attribute, element) {
      const attributeValue = String(attribute);
      element = this.preferredElement(element);

      if (this.isNodeList(element)) {
        $console.warn('This function expects to use one DOM element', `Got ${typeof element}`, 'getAttribute');
        return '';
      }

      if (!this.isDomElement(element)) {
        return '';
      }

      if (element.getAttribute(attributeValue)) {
        return element.getAttribute(attributeValue);
      } else {
        while (element !== document) {
          if (element.getAttribute(attributeValue)) {
            return element.getAttribute(attributeValue);
          }
          element = element.parentNode;
        }
        return '';
      }
    }

    /**
     * Set attribute
     * @param {string} [attribute]
     * @param {string} [value]
     * @param {object} [element]
     * @returns {string}
     */
    setAttribute(attribute, value, element) {
      const attributeValue = String(attribute);
      value = String(value);
      element = this.preferredElement(element);

      if (this.isNodeList(element)) {
        $console.warn('This function expects to use one DOM element', `Got ${typeof element}`, 'setAttribute');
        return '';
      }

      if (!this.isDomElement(element)) {
        return '';
      }

      element.setAttribute(attributeValue, value);

      return this.getAttribute(attributeValue, element);
    }

    /**
     * Remove attribute
     * @param {string} attribute
     * @param {object} [element]
     * @returns {boolean}
     */
    removeAttribute(attribute, element) {
      const attributeValue = String(attribute);
      element = this.preferredElement(element);

      if (this.isNodeList(element)) {
        $console.warn('This function expects to use one DOM element', `Got ${typeof element}`, 'setAttribute');
        return false;
      }

      if (!this.isDomElement(element)) {
        return false;
      }

      if (element.hasAttribute(attributeValue)) {
        element.removeAttribute(attributeValue);
        return true;
      }
      return false;
    }

    /**
     * Find element only looping up the DOM to then look back down
     * @param {string} find
     * @param {object} [element]
     * @returns {elementsModule}
     */
    closest(find, element) {
      const findString = String(find);
      element = this.preferredElement(element);

      if (!this.isDomElement(element)) {
        $console.warn('This function expects to use one DOM element', `Got ${typeof element}`, 'closest');
        this.element = document;
        this.elements = [];
        return this;
      }

      var parentElement = function() {
        if (element.querySelectorAll(findString).length) {
          return element.querySelector(findString);
        }

        if (element.parentNode.querySelector(findString)) {
          return element.parentNode.querySelector(findString);
        }
        while (element !== document) {
          if (element.querySelector(findString)) {
            return element.querySelector(findString);
          }
          element = element.parentNode;
        }
        return document;
      };

      this.element = parentElement();
      this.elements = [];
      return this;
    }

    /**
     * Closest Improved (closest function on steroids)
     * @param {string} find
     * @param {object} [element]
     * @returns {object}
     */
    closestI(find, element) {
      const findString = String(find);
      element = this.preferredElement(element);

      if (!this.isDomElement(element)) {
        $console.warn('This function expects to use one DOM element', `Got ${typeof element}`, 'closestI');
        this.element = document;
        this.elements = [];
        return this;
      }

      var findElement = function(element, elementFound, parentFound, sameLevel) {
        // Loop through number of possible candidates
        for (var i = 0; i < elementFound.length; i++) {
          if (sameLevel && elementFound[i].parentNode.contains(element)) {
            return elementFound[i];
          }

          if (elementFound[i].contains(element)) {
            return elementFound[i];
          }
        }

        // Element is deeply nested within either previous or next element sibling
        for (var ii = 0; ii < elementFound.length; ii++) {
          var elementFoundCopy = elementFound[ii];

          while (elementFoundCopy !== document && !elementFoundCopy.contains(element)) {
            if (elementFoundCopy.previousElementSibling) {
              if (elementFoundCopy.previousElementSibling.contains(element)) {
                return elementFound[ii];
              }
            }

            if (elementFoundCopy.nextElementSibling) {
              if (elementFoundCopy.nextElementSibling.contains(element)) {
                return elementFound[ii];
              }
            }
            elementFoundCopy = elementFoundCopy.parentNode;
          }
        }
        return element;
      };

      var parentElement = () => {
        if (element.querySelectorAll(findString).length) {
          return element.querySelector(findString);
        }

        if (element.parentNode.querySelectorAll(findString).length) {
          return findElement(element, element.parentNode.querySelectorAll(findString), element.parentNode, true);
        }

        while (element !== document) {
          if (element.querySelectorAll(findString).length) {
            return findElement(this.element, element.querySelectorAll(findString), element);
          }
          element = element.parentNode;
        }
        return document;
      };

      this.element = parentElement();
      this.elements = [];
      return this;
    }

    /**
     * Check for a class name
     * @param className
     * @param [element]
     * @returns {boolean}
     */
    hasClass(className, element) {
      className = String(className);
      element = this.preferredElement(element);

      if (!this.isDomElement(element)) {
        $console.warn('This function expects to use one DOM element', `Got ${typeof element}`, 'hasClass');
        return false;
      }

      if (element.className.indexOf(className) === -1) {
        return false;
      }

      if (element.classList) {
        return element.classList.contains(className);
      }

      var classes = element.className.split(' ');
      for (var i = 0, classesLength = classes.length; i < classesLength; i++) {
        if (classes[i] === className) {
          return true;
        }
      }
      return false;
    }


    /**
     * Modify classes
     * @param action
     * @param className
     * @param element
     * @returns {boolean}
     */
    modifyClass(action, className, element) {
      var classes = this.getAttribute('class', element).split(' ');

      for (var i = 0, classesLength = classes.length; i < classesLength; i++) {
        if (classes[i] === className) {
          if (action === 'remove') {
            classes.splice(i, 1);
            break;
          } else {
            return true;
          }
        }
      }

      if (action === 'add') {
        classes.push(className);
      }

      this.setAttribute('class', classes.join(' '), element);

      return element.className.indexOf(className) > -1;
    }

    /**
     * Add a class name
     * @param className
     * @param element
     */
    addClass(className, element) {
      element = this.preferredElement(element);

      if (!this.isDomElement(element)) {
        $console.warn('This function expects to use one DOM element', `Got ${typeof element}`, 'addClass');
        return false;
      }

      if (typeof className === 'string') {
        className = [className];
      }

      for (var i = 0, classNameLength = className.length; i < classNameLength; i++) {
        let classString = className[i];
        if (element.classList) {
          element.classList.add(classString);
        } else {
          this.modifyClass('add', classString, element);
        }
      }
    }

    /**
     * Remove a class name
     * @param className
     * @param element
     */
    removeClass(className, element) {
      element = this.preferredElement(element);

      if (!this.isDomElement(element)) {
        $console.warn('This function expects to use one DOM element', `Got ${typeof element}`, 'removeClass');
        return false;
      }

      if (typeof className === 'string') {
        className = [className];
      }

      for (var i = 0, classNameLength = className.length; i < classNameLength; i++) {
        let classString = className[i];
        if (element.classList) {
          element.classList.remove(classString);
        } else {
          this.modifyClass('remove', classString, element);
        }
      }
    }

    /**
     * Toggle a class name
     * @param className
     * @param element
     */
    toggleClass(className, element) {
      element = this.preferredElement(element);

      if (!this.isDomElement(element)) {
        $console.warn('This function expects to use one DOM element', `Got ${typeof element}`, 'addClass');
        return false;
      }

      if (typeof className === 'string') {
        className = [className];
      }

      for (var i = 0, classNameLength = className.length; i < classNameLength; i++) {
        let classString = className[i];
        let currentClass = element.className;
        if (element.classList) {
          element.classList.toggle(classString);
        } else {
          if (currentClass.split(' ').indexOf(classString) > -1) {
            this.modifyClass('add', classString, element);
          } else {
            this.modifyClass('remove', classString, element);
          }
        }
      }
    }

    /**
     * Get text of element
     * @param {boolean} [shouldTrim]
     * @param [element]
     * @returns {string}
     */
    getText(shouldTrim, element) {
      element = this.preferredElement(element);

      if (!this.isDomElement(element)) {
        $console.warn('This function expects to use one DOM element', `Got ${typeof element}`, 'getText');
        return '';
      }

      let elementText = element.innerText || element.textContent;
      if (shouldTrim) {
        elementText = this.trim(elementText);
      }
      return elementText || '';
    }

    /**
     * Removes additional whitespace
     * @param {string} string
     * @return {string}
     */
    trim(string) {
      if (typeof string === 'string') {
        if (string.trim) {
          return string.trim();
        }
        return string.replace(/^\s+|\s+$/g, '');
      }
      return '';
    }

    /**
     * For each element
     * @param callback {function}
     * @param [element]
     * @param [count]
     * @returns {object | undefined}
     */
    forEach(callback, element, count = 0) {
      if (typeof callback !== 'function') {
        $console.error('Invalid value of callback', `Expected function to be Function, got ${typeof callback}`, 'forEach');
        return;
      }

      element = this.preferredElement(element);

      if (this.isDomElement(element)) {
        callback(this.init(element).element, count + 1);
        return;
      }

      if (this.isArray(element) || this.isNodeList(element)) {
        for (var i = 0; i < element.length; i++) {
          this.forEach(callback, element[i], i);
        }
      }
    }

    /**
     * Detect if the element is fully within the window
     * @param {object} [element]
     * @returns {boolean}
     */
    onScreen(element) {
      element = this.preferredElement(element);

      if (!this.isDomElement(element)) {
        $console.warn('This function expects to use one DOM element', `Got ${typeof element}`, 'onScreen');
      }

      let documentScrollTop = (document.documentElement || document.body.parentNode || document.body).scrollTop;
      let objectTop = element.offsetTop;
      let objectHeight = element.clientHeight;
      let windowHeight = window.innerHeight || document.documentElement.clientHeight;

      return ((objectTop <= documentScrollTop + windowHeight)
        && (objectTop >= documentScrollTop)
        || ((objectHeight + objectTop) - documentScrollTop >= 0)
        && (documentScrollTop + windowHeight) - (objectHeight + objectTop) >= 0);
    }

    /**
     * Scroll to an element/offset within a specific duration (ms)
     * @param {Element|number} elementOrOffset
     * @param {number} duration
     */
    scrollTo(elementOrOffset, ms) {
      // hack for header height
      const possibleStickyHeaders = [
        '.sticky-header',
        '.mainNavSection',
      ];

      if (!elementOrOffset || !window.scrollTo || !window.scroll) return;

      const scroll = window.scrollTo || window.scroll;

      const duration = Number(ms);
      const pageYOffset = window.pageYOffset || document.documentElement.scrollTop;
      const targetOffsetY = (() => {
        if (!(elementOrOffset instanceof Element)) return Number(elementOrOffset);
        const element = elementOrOffset;
        const stickyHeader = possibleStickyHeaders
          .map(s => document.querySelector(s))
          .find(el => !!el);
        const stickyHeaderHeight = stickyHeader? stickyHeader.offsetHeight: 0;
        return pageYOffset + element.getBoundingClientRect().top - stickyHeaderHeight;
      })();

      const offsetDifference = targetOffsetY - pageYOffset;

      // instant scroll
      if (!duration) return scroll(0, targetOffsetY);

      // smooth scroll
      let start;
      const animateScroll = (timestamp) => {
        if (!start) start = timestamp;
        const progress = Math.min(timestamp - start, duration) / duration;
        scroll(0, pageYOffset + progress * offsetDifference);
        if (progress >= 1) return;
        window.requestAnimationFrame(animateScroll);
      };

      window.requestAnimationFrame(animateScroll);
    }

    /**
     * Scroll to another element within specified element at a specific speed
     * @param {object} element
     * @param {number} element offset value
     * @param {number} speed
     */
    animateTo(element, to, duration) {
      Math.easeInOutQuad = function(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
      };
      let start = element.scrollTop;
      let change = to - start;
      let currentTime = 0;
      let increment = 10;

      let animateScroll = function() {
        currentTime += increment;
        let val = Math.easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if (currentTime < duration) {
          setTimeout(animateScroll, increment);
        }
      };
      animateScroll();
    }

    /**
     * Element contains string
     * @param {string} string (case sensitive)
     * @param {string | boolean} [attribute]
     * @param {Element | NodeList | Array} [element]
     * @returns {elementsModule}
     */
    containsString(string, attribute, element) {
      element = this.preferredElement(element);

      if (typeof string !== 'string') {
        $console.error('Invalid value of param', `Expected string to be String, got ${typeof string}`, 'containsString');
      }

      if (!element || element.length === 0) {
        $console.error('Invalid value of param', `Expected object to be Element | NodeList | Array, got ${typeof element}`, 'containsString');
      }

      var filterElements = function(string, attribute, element) {

        if (this.isDomElement(element)) {
          let textValue = this.getText(true, element);
          if (attribute === undefined && textValue) {
            if (textValue.indexOf(string) > -1) {
              this.elements.push(element);
            }
          } else if (element.getAttribute(attribute)) {
            if (element.getAttribute(attribute).indexOf(string) > -1) {
              this.elements.push(element);
            }
          }
        }

        if (this.isArray(element) || this.isNodeList(element)) {
          for (var i = 0; i < element.length; i++) {
            filterElements.call(this, string, attribute, element[i]);
          }
        }
      };

      this.elements = [];
      this.element = undefined;

      filterElements.call(this, string, attribute, element);

      return this;
    }

  }

  return elementsModule;
});
